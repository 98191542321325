<template>
    <DataTable :headers="headers"
        :data="items"
        :expandable="expandable"
        :editable="editable"
        :selectedItem="selectedItem"
        :filterItems="selectables"
        :filterSelected="filterSelected"
        :filterName="$t('type')"
        :title="$t('inventory.name')"
        :pagination="{sortBy: ['invType.name']}"
        type="inventory"
        v-on:filter-select="getRows(arguments[0])" />
</template>


<script>
import { mapActions } from 'vuex'
import { rest } from '../_helpers'
import DataTable from '@/components/Table.vue'

export default {
    data() {
        return {
            filterSelected: {},
            selectables: null,
            headers: [],
            defaultHeaders: [
                {
                    text: 'IN',
                    align: 'left',
                    sortable: false,
                    value: 'id',
                    type: 'id',
                },
                {
                    text: this.$t('type'),
                    align: 'left',
                    sortable: true,
                    value: 'type',
                    type: 'string',
                    repr: (row, value) => value.toUpperCase(),
                    reset: item => { item.type = item.invType.name },
                },
                {
                    text: this.$t('model'),
                    align: 'left',
                    sortable: true,
                    value: 'model',
                    type: 'string',
                },
                {
                    text: this.$t('manufacturer'),
                    align: 'left',
                    sortable: true,
                    value: 'make',
                    type: 'string',
                },
                /* {
                    text: this.$t('vendor'),
                    align: 'left',
                    sortable: true,
                    value: 'vendor',
                    type: 'string',
                },
                {
                    text: this.$t('price'),
                    align: 'left',
                    contentAlign: 'right',
                    sortable: true,
                    width: '80px',
                    value: 'price',
                    type: 'int',
                    repr: (row, value) => {
                        return (value ? value : '0') + ' &#8364;'
                    }
                },
                {
                    text: this.$t('ordered'),
                    align: 'left',
                    sortable: true,
                    value: 'dateOrdered',
                    type: 'date',
                }, */
                {
                    text: this.$t('status'),
                    align: 'left',
                    sortable: true,
                    value: 'status',
                    type: 'string',
                    selectables: [this.$t('unknown'), this.$t('in_stock'), this.$t('in_use'), this.$t('unusable'), this.$t('rejected')],
                    repr: (row, value) => {
                        let status = this.$t('unknown')
                        if (value === 1) {
                            status = this.$t('in_stock')
                        } else if (value === 2) {
                            status = this.$t('in_use')
                        } else if (value === 3) {
                            status = this.$t('unusable')
                        } else if (value === 4) {
                            status = this.$t('rejected')
                        }
                        return status
                    },
                },
                {
                    text: this.$t('status_since'),
                    align: 'left',
                    sortable: true,
                    value: 'inStatusSince',
                    type: 'date',
                },
                {
                    text: this.$t('healthy'),
                    align: 'center',
                    value: 'healthy',
                    type: 'bool',
                    level: item => (item.healthy === false ? 1 : 0),
                },
                {
                    text: this.$t('location'),
                    align: 'left',
                    sortable: true,
                    value: 'local',
                    type: 'string',
                    repr: (row, value) => value.toUpperCase(), // value.charAt(0).toUpperCase() + value.slice(1)
                    reset: item => { item.local = item.location.name },
                },
                {
                    text: this.$t('serialnumber'),
                    align: 'left',
                    sortable: true,
                    value: 'serialNumber',
                    type: 'string',
                },
                /* {
                    text: 'System',
                    align: 'left',
                    sortable: false,
                    value: 'plugged.systemId',
                    type: 'link',
                    link: (item) => {
                        this.$router.push('/system/' + item.plugged.systemId)
                    },
                    linkIf: (item) => {
                        return item.plugged && (item.plugged.systemId) ? true : false
                    },
                    // linkIcon: 'link',
                    linkName: (item) => {
                        return `${item.plugged.system.project.name} ${item.plugged.system.camNo}`
                    },
                }, */
                {
                    text: this.$t('identifier'),
                    value: 'identifier',
                    align: 'left',
                    sortable: true,
                    type: 'string',
                    width: '10em',
                },
                {
                    text: this.$t('VPN'),
                    value: 'routerVpn',
                    align: 'left',
                    sortable: true,
                    width: '3em',
                    type: 'link',
                    href: item => `${item.router.vpn}`,
                    linkCopy: true,
                    linkName: item => item.routerVpn,
                    linkIf: item => item.router,
                    linkBlank: true,
                },
                {
                    text: this.$t('system'),
                    align: 'left',
                    sortable: false,
                    value: 'plugged',
                    type: 'string',
                    width: '6em',
                    link: id => this.getSystems(id)
                        .then(result => {
                            let currentSystem = null
                            if (result && result.length) {
                                const plug = result[0].plugged
                                if (plug && plug.system) {
                                    currentSystem = {
                                        title: `${plug.system.project.name} ${plug.system.camNo}`,
                                        href: () => `/system/${plug.systemId}`,
                                        from: plug.datePlugged,
                                        to: plug.dateUnplugged,
                                    }
                                }
                            }

                            return currentSystem
                        }),
                },
                /* {
                    text: 'Prev',
                    align: 'left',
                    sortable: false,
                    value: 'plugs',
                    type: 'link',
                    isList: true,
                    link: (item) => {
                        let links = []
                        if (item.plugs) {
                            let oldIds = []
                            for (let plug of item.plugs) {
                                if (plug.systemId && !oldIds.includes(plug.systemId)) {
                                    const link = {
                                        click: () => this.$router.push('/system/' + plug.systemId),
                                        name: `${plug.system.project.name} ${plug.system.camNo}`
                                    }
                                    links.push(link)
                                    oldIds.push(plug.systemId)
                                }
                            }
                        }
                        return links
                    },
                }, */
                {
                    text: this.$t('prev'),
                    align: 'left',
                    sortable: false,
                    value: 'plugs',
                    width: '3em',
                    type: 'menu',
                    menu: id => this.getSystems(id, true)
                        .then(result => {
                            const prevProjects = []
                            if (result && result.length) {
                                let lastSystemId = null
                                result[0].plugs.forEach(plug => {
                                    if (plug.system) {
                                        if (plug.systemId !== lastSystemId) {
                                            const prevItem = {
                                                title: `${plug.system.project.name} ${plug.system.camNo}`,
                                                href: () => `/system/${plug.systemId}`,
                                                from: plug.datePlugged,
                                                to: plug.dateUnplugged,
                                            }
                                            prevProjects.push(prevItem)

                                            lastSystemId = plug.systemId
                                        } else {
                                            prevProjects[prevProjects.length - 1].to = plug.dateUnplugged
                                        }
                                    }
                                })
                            }

                            return prevProjects.sort((a, b) => a.from < b.from)
                        }),
                    linkIcon: 'mdi-format-list-bulleted',
                },
                /* {
                    text: 'Previous Projects',
                    align: 'left',
                    sortable: false,
                    value: 'prevProjects',
                    type: 'string',
                    repr: (row, value) => {
                        return value ? value.split(',').join(', ') : ''
                    }
                }, */
            ],
            items: [],
            expandable: true,
            editable: true,
            selectedItem: null,
        }
    },

    components: {
        DataTable,
    },

    methods: {
        ...mapActions({
            showSpinner: 'page/showSpinner',
            setNew: 'input/setNew',
            resetNew: 'input/resetNew',
        }),

        getSystems(id, prev) {
            const payload = {
                attributes: ['id'],
                where: { id },
                includes: [
                    {
                        type: prev ? 'plug' : 'plugged',
                        attributes: ['systemId', 'projectId', 'dateUnplugged', 'datePlugged'],
                    },
                ],
            }

            return rest.getRows('inventory', payload)
        },

        getRows(filter) {
            const newHeaders = [...this.defaultHeaders]
            if (filter.text !== 'ROUTER') {
                newHeaders.splice(10, 1)
            }

            if (filter.text !== 'COMPUTER') {
                newHeaders.splice(9, 1)
            }

            this.headers = newHeaders

            this.items = []
            this.showSpinner(true)
            const payload = {
                // where: { type: 'camera' },
                // where: { id: { $in: [2049, 2050, 2051] } },
                // limit: 100,
                attributes: {
                    include: [['computer.name', 'identifier'],
                        ['router.vpn', 'routerVpn'],
                        ['invType.name', 'type'],
                        ['location.name', 'local']],
                    exclude: ['updatedAt', 'createdAt'],
                },
                includes: [
                    {
                        type: 'note',
                        attributes: ['id'],
                    },
                    { type: 'invType' },
                    { type: 'location' },
                    { type: 'computer' }, // , attributes: ['id']},
                    { type: 'camera' }, // , attributes: ['id']},
                    { type: 'housing' }, // , attributes: ['id']},
                    { type: 'router' }, // , attributes: ['id']},
                    { type: 'lens' }, // , attributes: ['id']},
                    /* {
                        type: 'plug',
                        attributes: ['systemId', 'projectId', 'dateUnplugged']
                    }, */
                    /* {
                        type: 'plugged',
                        attributes: ['systemId', 'projectId', 'datePlugged']
                    }, */
                    /* {
                        type: 'system',
                        attributes: ['id', 'enabled', 'camNo']
                    }, */
                ],
            }

            if (Object.keys(filter).length > 0 && filter.value !== null) {
                if (!payload.where) {
                    payload.where = {}
                }
                payload.where = { invTypeId: filter.value }
            }

            return rest.getRows('inventory', payload)
                .then(items => {
                    if (this.$route.params.id) {
                        const idPos = items.map(x => x.id).indexOf(parseInt(this.$route.params.id, 10))
                        this.selectedItem = items[idPos]
                    }

                    this.items = items
                })
                .finally(() => {
                    this.showSpinner(false)
                })
        },

        getTypes() {
            const payload = {
                attributes: [['id', 'value'], ['name', 'text']],
            }

            return rest.getRows('invType', payload)
                .then(items => {
                    if (items) {
                        const selectables = items.map(x => ({ value: x.value, text: x.text.toUpperCase() }))

                        this.selectables = [...[{ text: 'All', value: null }], ...selectables]
                    }
                })
        },

        customSearch(value, search, item) {
            const searchByCol = search.split('::')
            if (searchByCol.length === 2
                && Object.hasOwnProperty.call(item, searchByCol[0])
                && item[searchByCol[0]] !== null) {
                return item[searchByCol[0]].toString().toLowerCase().includes(searchByCol[1])
            }
            return this.deepCheck(item, search) // Object.values(item).some(v=>v&&v.toString().toLowerCase().includes(search))
        },

        deepCheck(obj, text) {
            text = text.toString().toLowerCase()
            let exists = false

            if (obj !== null) {
                const values = Object.values(obj)

                for (const val of values) {
                    const type = typeof val

                    if (type === 'object') {
                        exists = this.deepCheck(val, text)
                    } else if (type === 'array') {
                        for (const j of val) {
                            exists = this.deepCheck(j, text)

                            if (exists) {
                                break
                            }
                        }
                    } else {
                        const data = val.toString().toLowerCase()
                        exists = data.includes(text)
                    }

                    if (exists) {
                        break
                    }
                }
            } else if (!text) {
                exists = true
            }

            return exists
        },
    },

    mounted() {
        this.showSpinner(true)
    },

    created() {
        this.setNew({
            type: 'inventory',
            title: 'Inventory',
            modified: (idx, item) => {
                this.items.push(item)

                this.resetNew()
            },
        })

        this.getTypes()
            .then(() => {
                let catSelected = null
                if (this.$route.params.category) {
                    catSelected = this.$route.params.category.toUpperCase()

                    this.selectables.forEach(i => {
                        if (catSelected === i.text) {
                            this.filterSelected = i
                        }
                    })
                }

                this.headers = this.defaultHeaders
            })
            .then(() => {
                this.getRows(this.filterSelected)
            })
    },

    watch: {
        filterSelected(val) {
            // console.log('bla', val)
        },
    },
}
</script>


<style lang="stylus">
</style>
